import { createVuetify } from 'vuetify';
import * as components from 'vuetify/components';
import * as directives from 'vuetify/directives';
import colors from 'vuetify/lib/util/colors';

export const vuetify = createVuetify({
  components,
  directives,
  theme: {
    themes: {
      light: { ...colors },
      dark: { ...colors },
    },
  },
});
