const state = () => ({
  accessToken: localStorage.getItem('accessToken') || '',
  loaders: {
    moderationLoader: false,
    changesSaved: false,
    chatLoader: false,
  },
  dialogs: {
    profileSettingsFetishes: false,
    profileSettingsGoals: false,
    profileSettingsSaveChanges: false,
    profileSettingsProfilePreview: false,
    profileSettingsExplicitContent: false,
    profileSettingsFeedback: false,
    profileSettingsFeedbackReceived: false,
    userProfileSelectedImage: false,
    youHaveNewDialog: false,
    clearChatHistory: false,
    deleteAndBlock: false,
    deleteAccount: false,
    contributeImprovement: false,
    chatSettingsMobile: false,
    showYourSelf: false,
    chatSendImage: false,
    chatWarning: false,
    decisiveActionsMobile: false,
    chatImageViewer: false
  },
  dialogData: {},
});

const getters = {
  accessToken: (state) => state.accessToken,
  dialogs: (state) => state.dialogs,
  loaders: (state) => state.loaders,
  dialogData: (state) => state.dialogData,
};

const actions = {
  setAccessToken({ commit }, token) {
    commit('SET_ACCESS_TOKEN', token);
  },

  openDialog({ commit }, key) {
    commit('SET_OPEN_DIALOG', key);
  },

  closeDialog({ commit }, key) {
    commit('SET_CLOSE_DIALOG', key);
  },

  setLoaders({ commit }, payload) {
    commit('SET_LOADERS', payload);
  },

  async setDialogData(context, { dialog, data }) {
    context.commit('SET_DIALOG_DATA', { dialog, data });
  },
};

const mutations = {
  SET_ACCESS_TOKEN(state, token) {
    state.accessToken = token;
  },

  SET_OPEN_DIALOG(state, key) {
    state.dialogs[key] = true;
  },

  SET_CLOSE_DIALOG(state, key) {
    state.dialogs[key] = false;
  },

  SET_LOADERS(state, payload) {
    state.loaders[payload.key] = payload.value;
  },

  SET_DIALOG_DATA: (state, { dialog, data }) => {
    state.dialogData[dialog] = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
