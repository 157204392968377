import { apiClient } from '@/main';

export async function handleGetUserInfo() {
  try {
    return await apiClient.get('/user');
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetExtremePractices() {
  try {
    return await apiClient.get('/extreme-practices/names');
  } catch (error) {
    console.error(error);
  }
}

export async function handleUpdateUserInfo(data) {
  try {
    return await apiClient.put('/user/profile', data);
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetPrivacySettings(data) {
  try {
    return await apiClient.get('/user/settings', data);
  } catch (error) {
    console.error(error);
  }
}

export async function handleAllowExplicitContent(value) {
  try {
    return await apiClient.post('/user/settings/explicit-content', { show_explicit_content: value });
  } catch (error) {
    console.error(error);
  }
}

export async function handleAllowSendPhotos(value) {
  try {
    return await apiClient.post('/user/settings/restrict-photos', { allow_send_photos: value });
  } catch (error) {
    console.error(error);
  }
}

export async function handleAllowSendLinks(value) {
  try {
    return await apiClient.post('/user/settings/restrict-links', { allow_send_links: value });
  } catch (error) {
    console.error(error);
  }
}

export async function handleDeleteBlackListUser(id) {
  try {
    return await apiClient.delete(`/blacklist/${id}`);
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetBlackListUsers() {
  try {
    return await apiClient.get('/blacklist');
  } catch (error) {
    console.error(error);
  }
}

export async function handleSendToBlackListUser(id) {
  try {
    return await apiClient.post('user/block', { blocked_user_id: id });
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetUserProfileInfo(id) {
  try {
    return await apiClient.get(`/profile/${id}`);
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetUserProfiles(payload) {
  try {
    let requestUrl = '/profiles';

    requestUrl += `?page=${payload.page || 1}`;

    if (payload.age_from) {
      requestUrl += `&age_from=${payload.age_from}`;
    }

    if (payload.age_to) {
      requestUrl += `&age_to=${payload.age_to}`;
    }

    if (payload.photo && payload.photo.length) {
      payload.photo.forEach(photo => {
        requestUrl += `&has_photo=${photo}`;
      });
    }

    if (payload.ads && payload.ads.length) {
      payload.ads.forEach(ads => {
        requestUrl += `&announcement=${ads}`;
      });
    }

    if (payload.gender && payload.gender.length) {
      payload.gender.forEach(gender => {
        requestUrl += `&gender=${gender}`;
      });
    }

    if (payload.location && payload.location.length) {
      payload.location.forEach(location => {
        requestUrl += `&city[]=${location}`;
      });
    }

    if (payload.position && payload.position.length) {
      payload.position.forEach(position => {
        requestUrl += `&position[]=${position}`;
      });
    }

    if (payload.fetishes && payload.fetishes.length) {
      payload.fetishes.forEach(fetish => {
        requestUrl += `&fetish[]=${fetish}`;
      });
    }

    if (payload.goals && payload.goals.length) {
      payload.goals.forEach(goal => {
        requestUrl += `&goals[]=${goal}`;
      });
    }

    return await apiClient.get(requestUrl);
  } catch (error) {
    console.error(error);
  }
}

export async function handleLikeProfile(payload) {
  try {
    return await apiClient.post('/profiles/like', payload);
  } catch (error) {
    console.error(error);
  }
}
