<template>
  <div class="app-wrapper">
    <router-view />

    <!-- DIALOGS -->
    <post-ad-dialog v-if="dialogs.adPlace"/>
    <chat-have-new-dialog v-if="dialogs.youHaveNewDialog" />
  </div>
</template>

<script setup>
import { computed, onBeforeUnmount, onMounted } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import echo from '@/echo';
import { toNumber } from 'lodash';
import PostAdDialog from '@/components/dialogs/PostAdDialog.vue';
import ChatHaveNewDialog from '@/components/dialogs/ChatHaveNewDialog.vue';
import { handleGetUserInfo } from '@/api/Profile';
import { handleGetUnreadMessagesCount, handleUpdateMessageStatus } from '@/api/Chat';
import '@/assets/css/main.scss';
import './themes/theme.css';

const store = useStore();
const route = useRoute();
const router = useRouter();

const typingTimeouts = new Map();

const dialogs = computed(() => store.state.main.dialogs);
const userInfo = computed(() => store.getters['profile/userInfo']);
const chatList = computed(() => store.getters['chat/chatList']);
const selectedUserChatMessages = computed(() => store.getters['chat/selectedUserChatMessages']);

echo.connector.pusher.connection.bind('connected', () => {
  console.log('WebSocket connected successfully');
});

onMounted(() => {
  document.documentElement.className = 'dark';
  getUserInfo();

  if(localStorage.getItem('accessToken')) {
    getUnreadMessagesUserCount();
  }
});

onBeforeUnmount(() => {
  if (userInfo.value && userInfo.value.id) {
    echo.leave(`user.${userInfo.value.id}`);
  }
});

async function getUserInfo() {
  const response = await handleGetUserInfo();

  if (response && response.data) {
    store.dispatch('profile/setUserInfo', response.data);
    startSockets();
  }
}

async function getUnreadMessagesUserCount() {
  const response = await handleGetUnreadMessagesCount();

  if (response && response.data) {
    store.dispatch('chat/setUnreadMessagesCount', response.data.unread_messages_count || 0);
  }
}

function userIsTyping(data) {
  const updatedList = chatList.value.map((el) => {
    if (el?.interlocutor_id === data?.user_id) {
      return { ...el, typingNow: data?.typing };
    }
    return el;
  });

  store.dispatch('chat/setChatList', updatedList);

  if (typingTimeouts.has(data.user_id)) {
    clearTimeout(typingTimeouts.get(data.user_id));
  }

  const timeout = setTimeout(() => {
    const clearedList = chatList.value.map((el) => {
      if (el?.interlocutor_id === data?.user_id) {
        return { ...el, typingNow: false };
      }
      return el;
    });

    store.dispatch('chat/setChatList', clearedList);
    typingTimeouts.delete(data.user_id);
  }, 2500);

  typingTimeouts.set(data.user_id, timeout);
}

async function addUserWritingMessage(data) {
  if (route.name === 'ChatUser' && parseInt(atob(route.params.id)) === data.from_user_id) {
    const updatedUserChatMessages = [
      ...selectedUserChatMessages.value,
      data
    ];
    store.dispatch('chat/setSelectedUserChatMessages', updatedUserChatMessages);

    const updateStatusData = {
      user_id: data.from_user_id,
      status: 'read'
    };

    await handleUpdateMessageStatus(updateStatusData);
  }
}

function addMySendingMessage(data) {
  if (route.name === 'ChatUser' && parseInt(atob(route.params.id)) === data.to_user_id) {
    const updatedUserChatMessages = [
      ...selectedUserChatMessages.value,
      data
    ];
    store.dispatch('chat/setSelectedUserChatMessages', updatedUserChatMessages);
  }
}

function userIsBlocked(data) {
  const userId = parseInt(atob(route.params.id));
  const isChatUserBlocked = route.name === 'ChatUser' && (userId === data.blocked_user_id || userId === data.user_id);

  if (route.name === 'Chat' || isChatUserBlocked) {
    store.dispatch('chat/resetSelectedUserChat');
    const filteredList = chatList.value.filter(
      el => el.interlocutor_id !== data.blocked_user_id && el.interlocutor_id !== data.user_id
    );
    store.dispatch('chat/setChatList', filteredList);
    router.push({ name: 'Chat' });
  }
}

function handleDeleteChatForBoth(data) {
  const userId = parseInt(atob(route.params.id));
  const isChatUser = route.name === 'ChatUser' && (userId === data.interlocutor_id || userId === data.user_id);

  if (route.name === 'Chat' || isChatUser) {
    store.dispatch('chat/setSelectedUserChatMessages', []);
  }
}

function changeEditedMessage(data) {
  if (selectedUserChatMessages.value.length && route.name === 'ChatUser') {
    const updatedMessagesList = selectedUserChatMessages.value.map(el => {
      if (el.id === data.id) {
        return { ...el, content: data.content };
      }

      return el;
    });

    store.dispatch('chat/setSelectedUserChatMessages', updatedMessagesList);
  }
}

function removeDeletedMessage(data) {
  if (selectedUserChatMessages.value.length && route.name === 'ChatUser') {
    const updatedMessagesList = selectedUserChatMessages.value.filter(el => el.id !== toNumber(data.id));

    store.dispatch('chat/setSelectedUserChatMessages', updatedMessagesList);
  }
}

function removePhotoFromList(data) {
  if (selectedUserChatMessages.value.length && route.name === 'ChatUser') {
    const updatedMessagesList = selectedUserChatMessages.value.map(el => {
      if(el.id === data.message_id) {
        el.photos = el.photos.filter(photo => photo.id !== data.photo_id);
      }
    });

    store.dispatch('chat/setSelectedUserChatMessages', updatedMessagesList);
  }
}

function changeUserUnreadMessagesCount(data) {
  const dataLookup = data.reduce((acc, item) => {
    acc[item.from_user_id] = {
      unread_count: item.unread_count,
      last_message: item.last_message,
      last_message_time: item.created_at,
    };

    return acc;
  }, {});

  const updatedList = chatList.value.map((el) => {
    if (el?.interlocutor_id in dataLookup) {
      return {
        ...el,
        unread_count: dataLookup[el.interlocutor_id].unread_count,
        last_message: dataLookup[el.interlocutor_id].last_message.content,
        last_message_time: dataLookup[el.interlocutor_id].last_message.created_at,
      };
    }
    return el;
  });

  store.dispatch('chat/setChatList', updatedList);
}

function handleLikeOrUnlikeMessage(data) {
  const likedOrUnlikedMessages = selectedUserChatMessages.value.map((el) => {
    if (el?.id === data?.message_id) {
      if(data.photo_id) {
        el.photos.map(photo => {
          if (photo.id === data.photo_id) {
            return { ...photo, likes_types: data.type };
          }
        });
      }
      
      return { ...el, likes_types: data.type };
    }
    return el;
  });

  store.dispatch('chat/setSelectedUserChatMessages', likedOrUnlikedMessages);
}

function startSockets() {
  if (userInfo.value && userInfo.value.id) {
    echo.private(`user.${userInfo.value.id}`)
      .listen('.LikeNotification', (event) => {
        console.log('LikeNotification received:', event);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.UnreadMessagesFromUsersUpdated', (event) => {
        changeUserUnreadMessagesCount(event.unread_messages_from_users);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.UnreadMessagesCountUpdated', (event) => {
        store.dispatch('chat/setUnreadMessagesCount', event.unread_messages_count || 0);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.MessageLikedToSender', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.MessageLiked', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.UserBlocked', (data) => {
        userIsBlocked(data);
      });
    echo.private(`user.${userInfo.value.id}`)
      .listen('.ChatDeletedForBoth', (data) => {
        handleDeleteChatForBoth(data);
      });
    echo.private(`messages.${userInfo.value.id}`)
      .listen('.MessageRemovedPhoto', (data) => {
        removePhotoFromList(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MessageUnlikedToSender', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MessageUnliked', (data) => {
        handleLikeOrUnlikeMessage(data);
      });
    echo.channel(`typing.${userInfo.value.id}`)
      .listen('.TypingIndicator', (data) => {
        userIsTyping(data);
      });
    echo.channel(`chat.${userInfo.value.id}`)
      .listen('.MessageSent', (data) => {
        addUserWritingMessage(data);
      });
    echo.channel(`chat.${userInfo.value.id}`)
      .stopListening('.MessageSentToSender')
      .listen('.MessageSentToSender', (data) => {
        addMySendingMessage(data);
      });
    echo.channel(`user.${userInfo.value.id}`)
      .listen('.MutualLike', (data) => {
        store.dispatch('main/setDialogData', { dialog: 'chatHaveNewDialog', data: data.profileId });
        store.dispatch('main/openDialog', 'youHaveNewDialog');
      });
    echo.channel(`message.${userInfo.value.id}`)
      .listen('.MessageDeleted', (data) => {
        removeDeletedMessage(data);
      });
    echo.channel(`chat.${userInfo.value.id}`)
      .listen('.MessageEdited', (data) => {
        changeEditedMessage(data);
      });
  } else {
    console.warn('User info is not available. Cannot start sockets.');
  }
}
</script>

<style>
.app-wrapper {
  font-family: "Exo 2", serif;
  display: flex;
  justify-content: center;
  flex: 1;
}
</style>
