import { createRouter, createWebHistory } from 'vue-router';

const authenticationCheck = (to, from, next) => {
  const token = localStorage.getItem('accessToken');

  if (!token && to.name !== 'Welcome' && to.name !== 'Login') {
    return next({ name: 'Login' }); // Change router link to Welcome
  }

  if (token && (to.name === 'Welcome' || to.name === 'Login')) {
    return next({ name: 'Main' });
  }

  next();
};

const routes = [
  {
    path: '/',
    name: 'Main',
    component: () => import('@/views/MainLayout.vue'),
    beforeEnter: authenticationCheck,
    children: [
      {
        path: '',
        name: 'UserFeed',
        component: () => import('@/views/UserFeed.vue'),
      },
      {
        path: '/user/profile/:id',
        name: 'UserProfile',
        component: () => import('@/views/UserProfile.vue'),
      },
      {
        path: '/chat',
        name: 'Chat',
        component: () => import('@/views/Chat.vue'),
        children: [
          {
            path: 'user/:id',
            name: 'ChatUser',
            component: () => import('@/components/chat/components/ChatUser.vue')
          }
        ]
      },
    ],
  },
  {
    path: '/hideProfile',
    name: 'hideProfile',
    component: () => import('@/views/HideProfile.vue'),
    beforeEnter: authenticationCheck,
  },
  {
    path: '/profile/settings',
    name: 'ProfileSettings',
    component: () => import('@/views/ProfileSettings.vue'),
    beforeEnter: authenticationCheck,
    children: [
      {
        path: 'interests',
        name: 'Interests',
        component: () => import('@/components/profileSettings/ProfileSettingsInterests.vue'),
      },
      {
        path: 'privacy',
        name: 'Privacy',
        component: () => import('@/components/profileSettings/ProfileSettingsPrivacy.vue'),
      },
      {
        path: 'secretAlbum',
        name: 'secretAlbum',
        component: () => import('@/components/profileSettings/ProfileSettingsSecretAlbum.vue'),
      },
      {
        path: 'transactionHistory',
        name: 'transactionHistory',
        component: () => import('@/components/profileSettings/ProfileSettingsTransactionHistory.vue'),
      },
      {
        path: 'accountActions',
        name: 'accountActions',
        component: () => import('@/components/profileSettings/ProfileSettingsAccountActions.vue'),
      },
    ],
  },
  {
    path: '/auth',
    name: 'Auth',
    component: () => import('../views/Auth.vue'),
    beforeEnter: authenticationCheck,
    children: [
      {
        path: 'welcome',
        name: 'Welcome',
        component: () => import('@/components/auth/Welcome.vue'),
      },
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/components/auth/LoginWith.vue'),
      },
      {
        path: 'register',
        name: 'Register',
        component: () => import('@/components/auth/AuthRegister.vue'),
      },
      {
        path: 'fetishes',
        name: 'Fetishes',
        component: () => import('@/components/auth/AuthFetishes.vue'),
      },
      {
        path: 'goals',
        name: 'Goals',
        component: () => import('@/components/auth/AuthGoals.vue'),
      },
    ],
  },
  {
    path: '/safety-rules',
    name: 'SafetyRules',
    component: () => import('@/components/policies/SafetyRules.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;