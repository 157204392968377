const state = {
  imageUrl: null,
  chatList: [],
  selectedUserChat: {},
  selectedUserChatMessages: [],
  selectedUserChatPagination: { current_page: 0, per_page: 20 },
  chatMessagesNoMoreItems:  false,
  unreadMessagesCount: 0,
  chatSelectedImage: null,
  messageInputValue: '',
  selectedMessageIdForEdit: ''
};

const getters = {
  imageUrl: (state) => state.imageUrl,
  chatList: (state) => state.chatList,
  selectedUserChat: (state) => state.selectedUserChat,
  selectedUserChatMessages: (state) => state.selectedUserChatMessages,
  selectedUserChatPagination: (state) => state.selectedUserChatPagination,
  chatMessagesNoMoreItems: (state) => state.chatMessagesNoMoreItems,
  unreadMessagesCount: (state) => state.unreadMessagesCount,
  chatSelectedImage: (state) => state.chatSelectedImage,
  messageInputValue: (state) => state.messageInputValue,
  selectedMessageIdForEdit: (state) => state.selectedMessageIdForEdit,
};

const mutations = {
  SET_IMAGE_URL(state, url) {
    state.imageUrl = url;
  },
  SET_CHAT_LIST(state, payload) {
    state.chatList = payload;
  },
  SET_SELECTED_USER_CHAT(state, payload) {
    state.selectedUserChat = payload;
  },
  SET_SELECTED_USER_CHAT_MESSAGES(state, payload) {
    state.selectedUserChatMessages = payload;
  },
  SET_SELECTED_USER_CHAT_PAGINATION(state, payload) {
    state.selectedUserChatPagination = payload;
  },
  SET_CHAT_MESSAGES_NO_MORE_ITEMS(state, payload) {
    state.chatMessagesNoMoreItems = payload;
  },
  RESET_SELECTED_USER_CHAT(state) {
    state.selectedUserChatPagination = { current_page: 0, per_page: 20 };
    state.selectedUserChatMessages = [];
    state.selectedUserChat = {};
    state.chatMessagesNoMoreItems = false;
    state.messageInputValue = '';
    state.selectedMessageIdForEdit = '';
  },
  SET_UNREAD_MESSAGES_COUNT(state, payload) {
    state.unreadMessagesCount = payload;
  },
  SET_CHAT_SELECTED_IMAGE(state, payload) {
    state.chatSelectedImage = payload;
  },
  SET_MESSAGE_INPUT_VALUE(state, payload) {
    state.messageInputValue = payload;
  },
  SET_SELECTED_MESSAGE_ID_FOR_EDIT(state, payload) {
    state.selectedMessageIdForEdit = payload;
  },
};

const actions = {
  setImageUrl({ commit }, url) {
    commit('SET_IMAGE_URL', url);
  },

  setChatList({ commit }, payload) {
    commit('SET_CHAT_LIST', payload);
  },

  setSelectedUserChat({ commit }, payload) {
    commit('SET_SELECTED_USER_CHAT', payload);
  },

  setSelectedUserChatMessages({ commit }, payload) {
    commit('SET_SELECTED_USER_CHAT_MESSAGES', payload);
  },

  setSelectedUserChatPagination({ commit }, payload) {
    commit('SET_SELECTED_USER_CHAT_PAGINATION', payload);
  },

  setChatMessagesNoMoreItems({ commit }, payload) {
    commit('SET_CHAT_MESSAGES_NO_MORE_ITEMS', payload);
  },

  resetSelectedUserChat({ commit }, payload) {
    commit('RESET_SELECTED_USER_CHAT', payload);
  },

  setUnreadMessagesCount({ commit }, payload) {
    commit('SET_UNREAD_MESSAGES_COUNT', payload);
  },

  setChatSelectedImage({ commit }, payload) {
    commit('SET_CHAT_SELECTED_IMAGE', payload);
  },

  setMessageInputValue({ commit }, payload) {
    commit('SET_MESSAGE_INPUT_VALUE', payload);
  },

  setSelectedMessageIdForEdit({ commit }, payload) {
    commit('SET_SELECTED_MESSAGE_ID_FOR_EDIT', payload);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
