import { createStore } from 'vuex';
import main from './modules/main';
import auth from './modules/auth';
import profile from './modules/profile';
import chat from './modules/chat';

const store = createStore({
  modules: {
    main,
    auth,
    profile,
    chat
  },
});

export default store;
