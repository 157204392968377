<template>
  <Dialog
    :close-dialog="handleCloseDialog"
    dialogClass="max-w-[340px] md:max-w-[520px] w-[100%] flex flex-col items-center gap-[24px] p-[32px_24px] md:p-[64px_40px] bg-[--primary-background-dark] shadow-[0_-1px_1px_0_var(--auth-register-login-button-shadow-alfa-35),0_1px_2px_0_var(--auth-register-login-button-shadow-alfa-35),0_16px_24px_-8px_var(--auth-register-login-button-shadow-alfa-35)] backdrop-blur-md rounded-[16px] md:rounded-[32px] mx-[16px]"
  >
    <div class="flex flex-col gap-[12px] md:gap-[24px]">
      <span class="text-[--primary-invert] text-[20px] md:text-[28px] no-italic font-[700] leading-[120%] tracking-[0.4px] text-center">
        {{ $t('chat.communication.youLikedEachOther') }}
      </span>

      <span class="text-[13px] md:text-[16px] text-[--primary-invert-alfa-60] font-[400] leading-[140%] md:leading-[150%] md:tracking-[-0.25px] text-center">
        {{ $t('chat.communication.nowYouAreConnected') }}
      </span>
    </div>

    <man-icon
      class="md:w-[166px] md:h-[207px]"
    />

    <div class="flex gap-[6px]">
      <Button
        size="none"
        custom-class="p-[12px_18px] md:p-[16px_18px]"
        button-text-class="text-[13px] md:text-[16px] font-[400] md:font-[700] tracking-[0.2px] md:tracking-[0.8px]"
        :text="$t('button.later')"
        @handleClick="handleCloseDialog"
      />
      <Button
        size="none"
        custom-class="p-[12px_18px] md:p-[16px_18px]"
        button-text-class="text-[13px] md:text-[16px] font-[400] md:font-[700] tracking-[0.2px] md:tracking-[0.8px]"
        :text="$t('button.startConversation')"
        @handle-click="handleStartConversation"
      />
    </div>
  </Dialog>
</template>
<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import Dialog from '@/common/Dialog.vue';
import Button from '@/common/Button.vue';
import ManIcon from '@/assets/icons/manIcon.vue';

const store = useStore();
const router = useRouter();

const dialogData = computed(() => store.getters['main/dialogData']);

function handleCloseDialog () {
  store.dispatch('main/closeDialog', 'youHaveNewDialog');
  store.dispatch('main/setDialogData', { dialog: 'chatHaveNewDialog', data: null });
}

function handleStartConversation () {
  if (dialogData.value.chatHaveNewDialog) {
    const encodedId = btoa(dialogData.value.chatHaveNewDialog.toString());
    router.push({ path: `/chat/user/${encodedId}` });
  }

  handleCloseDialog();
}
</script>
