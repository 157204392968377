<template>
  <v-menu class="emojis-list-dropdown-wrapper" v-model="isDropdownOpen" location="bottom center">
    <template v-slot:activator="{ props }">
      <div
        class="d-flex emojis-list-dropdown-wrapper-popular-emojis"
        v-bind="props"
      >
        <div
          v-for="(emoji, index) in popularEmojis"
          :key="index"
          class="emojis-list-dropdown-emoji-item"
          @click.stop="$emit('selectEmoji', emoji)"
        >
          {{ emoji }}
        </div>
        <arrow-down-circle
          class="clickable-item emojis-list-dropdown-arrow-icon"
          :class="{
            'emojis-list-dropdown-arrow-icon-active': isDropdownOpen
          }"
        />
      </div>
      <div
        v-if="showActions && !isDropdownOpen"
        class="emojis-list-dropdown-actions mt-[4px]"
      >
        <span
          class="emojis-list-dropdown-actions-text"
          @click="$emit('handleEditMessage')"
        >
          {{ $t('button.changeMessage') }}
        </span>
        <span
          class="emojis-list-dropdown-actions-text"
          @click="$emit('handleDeleteMessage')"
        >
          {{ $t('button.delete') }}
        </span>
      </div>
    </template>

    <div class="d-flex flex-column emojis-list-dropdown-content">
      <div class="emojis-list-dropdown-all-list">
        <div
          v-for="(emoji, index) in emojisList"
          :key="index"
          class="emojis-list-dropdown-emoji-item"
          @click="$emit('selectEmoji', emoji)"
        >
          {{ emoji }}
        </div>
      </div>
      <div
        v-if="showActions && isDropdownOpen"
        class="emojis-list-dropdown-actions"
      >
        <span
          class="emojis-list-dropdown-actions-text"
          @click="$emit('handleEditMessage')"
        >
          {{ $t('button.changeMessage') }}
        </span>
        <span
          class="emojis-list-dropdown-actions-text"
          @click="$emit('handleDeleteMessage')"
        >
          {{ $t('button.delete') }}
        </span>
      </div>
    </div>
  </v-menu>
</template>

<script setup>
import { ref } from 'vue';
import { emojisList, popularEmojis } from '@/constants/emojies';
import ArrowDownCircle from '@/assets/icons/arrowDownCircle.vue';

const isDropdownOpen = ref(false);

defineProps({
  showActions: {
    type: Boolean,
    default: false,
  },
});
</script>

<style scoped lang="scss">
.emojis-list-dropdown-wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 12px;
}

.emojis-list-dropdown-wrapper-popular-emojis {
  display: flex;
  padding: 12px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: var(--primary-alfa-30);
  backdrop-filter: blur(12px);
}

.emojis-list-dropdown-arrow-icon {
  cursor: pointer;
  color: var(--primary-invert-alfa-30);
}

.emojis-list-dropdown-arrow-icon-active {
  transform: rotate(180deg);
  color: var(--primary-invert);
}

.emojis-list-dropdown-content {
  margin: 4px 0;
  gap: 4px;
  max-width: 208px;
  width: 100%;
}

.emojis-list-dropdown-all-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  max-height: 164px;
  overflow-y: auto;
  padding: 12px;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--primary-alfa-30);
  backdrop-filter: blur(12px);
}

.emojis-list-dropdown-all-list::-webkit-scrollbar {
  display: none;
}

.emojis-list-dropdown-emoji-item {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
}

.emojis-list-dropdown-actions {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 20px 12px;
  align-items: flex-start;
  border-radius: 16px;
  background: var(--primary-alfa-30);
  backdrop-filter: blur(12px);
}

.emojis-list-dropdown-actions-text {
  color: var(--primary-invert-alfa-40);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.2px;
  cursor: pointer;

  &:hover {
    color: var(--primary-invert);
  }
}
</style>
