import { i18n } from '@/i18n';

export const profileSettingInterests = [
  { name: i18n.global.t('common.profilePhotos'), value: 'profilePhotos' },
  { name: i18n.global.t('settings.personalInformation.descriptionAndInterests'), value: 'descriptionAndInterests' },
];

export const profileSettingPrivacy = [
  {
    name: i18n.global.t('settings.privacySettings.explicitContent'),
    value: 'explicitContent',
  },
  {
    name: i18n.global.t('settings.privacySettings.sendingPhotoAndVideoMessages'),
    value: 'sendingPhotoAndVideoMessages',
  },
  {
    name: i18n.global.t('settings.privacySettings.blackList'),
    value: 'blackList',
  },
  {
    name: i18n.global.t('settings.privacySettings.selectLanguage'),
    value: 'selectLanguage',
  },
];

export const languages = [
  {
    name: i18n.global.t('languages.ru'),
    value: 'ru',
  },
];

export const userDefaultValues = {
  extremePractices: [],
  fetishes: [],
  goals: [],
  languages: [],
  realExperiences: [],
  taboos: [],
  about_me: '',
  country: '',
  date_of_birth: '',
  experience: '',
  gender: '',
  height: '',
  interests: '',
  name: '',
  position: '',
  wildest_dreams: '',
};

export const profileListDefaultValues = {
  location: [],
  gender: [],
  goals: [],
  position: [],
  photo: [],
  ads: [],
  fetishes: [],
  age_from: 18,
  age_to: 120,
};