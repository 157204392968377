const state = () => ({
  preferences: {
    goals: [],
    fetishes: [],
  },
});

const getters = {
  preferences: (state) => state.preferences,
};

const actions = {
  setPreferences({ commit }, token) {
    commit('SET_PREFERENCES', token);
  },
};

const mutations = {
  SET_PREFERENCES(state, token) {
    state.preferences = token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
