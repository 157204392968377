import { createApp } from 'vue';
import store from './store';
import router from './router';
import { i18n } from '@/i18n';
import axios from 'axios';
import App from './App.vue';
import 'vuetify/styles';
import { vuetify } from '@/plugins/vuetify';
import echo from '@/echo';
import './assets/css/tailwind.css';

const app = createApp(App);

export const api = process.env.VUE_APP_API_BASE_URL;

export const apiClient = axios.create({
  baseURL: api,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
});

app.use(store);
app.use(i18n);
app.use(router);
app.use(vuetify);
app.config.globalProperties.$echo = echo;

window.Echo = echo;

app.mount('#app');
