import { profileListDefaultValues, userDefaultValues } from '@/constants/profileSettings';

const state = () => ({
  userInfo: userDefaultValues,
  editingUserInfo: userDefaultValues,
  selectedUserPage: userDefaultValues,
  privacySettings: {},
  blacklistUsers: [],
  userImagesList: [],
  filterList: profileListDefaultValues,
  selectedImage: '',
});

const getters = {
  userInfo: (state) => state.userInfo,
  editingUserInfo: (state) => state.editingUserInfo,
  selectedUserPage: (state) => state.selectedUserPage,
  privacySettings: (state) => state.privacySettings,
  blacklistUsers: (state) => state.blacklistUsers,
  userImagesList: (state) => state.userImagesList,
  filterList: (state) => state.filterList,
  selectedImage: (state) => state.selectedImage,
};

const actions = {
  setUserInfo({ commit }, payload) {
    commit('SET_USER_INFO', payload);
  },

  setEditingUserInfo({ commit }, payload) {
    commit('SET_EDITING_USER_INFO', payload);
  },

  setSelectedUserPage({ commit }, payload) {
    commit('SET_SELECTED_USER_PAGE', payload);
  },

  setEditingUserInfoAllList({ commit }, payload) {
    commit('SET_EDITING_USER_INFO_All_LIST', payload);
  },

  setPrivacySettingsList({ commit }, payload) {
    commit('SET_PRIVACY_SETTINGS_LIST', payload);
  },

  setBlacklistUsers({ commit }, payload) {
    commit('SET_BLACK_LIST_USERS', payload);
  },

  setUserImagesList({ commit }, payload) {
    commit('SET_USER_IMAGES_LIST', payload);
  },

  setFilterList({ commit }, payload) {
    commit('SET_FILTER_LIST', payload);
  },

  setSelectedImage({ commit }, image) {
    commit('SET_SELECTED_IMAGE', image);
  },
};

const mutations = {
  SET_USER_INFO(state, payload) {
    state.userInfo = payload;
  },

  SET_EDITING_USER_INFO(state, payload) {
    state.editingUserInfo[payload.key] = payload.value;
  },

  SET_FILTER_LIST(state, payload) {
    state.filterList[payload.key] = payload.value;
  },

  SET_EDITING_USER_INFO_All_LIST(state, payload) {
    state.editingUserInfo = payload;
  },

  SET_SELECTED_USER_PAGE(state, payload) {
    state.selectedUserPage = payload;
  },

  SET_PRIVACY_SETTINGS_LIST(state, payload) {
    state.privacySettings = payload;
  },

  SET_BLACK_LIST_USERS(state, payload) {
    state.blacklistUsers = payload;
  },

  SET_USER_IMAGES_LIST(state, payload) {
    state.userImagesList = payload;
  },

  SET_SELECTED_IMAGE(state, image) {
    state.selectedImage = image;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
