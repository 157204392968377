import { apiClient } from '@/main';

export async function handleGetChatList() {
  try {
    return await apiClient.get('/dialogs');
  } catch (error) {
    console.error(error);
  }
}

export async function handleSendTypingIndicator(to_user_id) {
  try {
    return await apiClient.post('/message/typing', { to_user_id });
  } catch (error) {
    console.error(error);
  }
}

export async function handleSendMessage(data, isMultiPartFormData) {
  try {
    const config = isMultiPartFormData
      ? { headers: { 'Content-Type': 'multipart/form-data' } }
      : {};

    return await apiClient.post(
      '/messages',
      data,
      config
    );
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetUserMessages(id, pagination) {
  try {
    let url = `/messages/${id}`;

    if (pagination) {
      if (pagination.current_page) {
        url += `?page=${pagination.current_page}`;
      }

      if (pagination.per_page) {
        url += `&per_page=${pagination.per_page}`;
      }
    }

    if(!pagination) {
      url += '?per_page=20&page=1';
    }

    return await apiClient.get(url);
  } catch (error) {
    console.error(error);
  }
}

export async function handleUpdateMessageStatus(payload) {
  try {
    return await apiClient.post('/message/update-status', payload);
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetUnreadMessagesCount() {
  try {
    return await apiClient.get('unread-messages-count');
  } catch (error) {
    console.error(error);
  }
}

export async function handleLikeMessage(payload) {
  try {
    return await apiClient.post(`messages/${payload.id}/like`, { type: payload.type, photo_id: payload.photo_id });
  } catch (error) {
    console.error(error);
  }
}

export async function handleUnLikeMessage(payload) {
  try {
    return await apiClient.post(`messages/${payload.id}/unlike`, { type: payload.type, photo_id: payload.photo_id });
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetChatWarnings() {
  try {
    return await apiClient.get('user-features');
  } catch (error) {
    console.error(error);
  }
}

export async function handleSendChatWarnings(payload) {
  try {
    return await apiClient.post('update-feature', payload );
  } catch (error) {
    console.error(error);
  }
}

export async function handleGetChatPermissions() {
  try {
    return await apiClient.get('user/permissions');
  } catch (error) {
    console.error(error);
  }
}

export async function handleSendChatPermissions(payload) {
  try {
    return await apiClient.post('user/permissions', payload );
  } catch (error) {
    console.error(error);
  }
}

export async function handleDeleteChatForMe(id) {
  try {
    return await apiClient.delete(`chat/delete-for-me/${id}` );
  } catch (error) {
    console.error(error);
  }
}

export async function handleDeleteChatForBoth(id) {
  try {
    return await apiClient.delete(`chat/delete-for-both/${id}` );
  } catch (error) {
    console.error(error);
  }
}

export async function handleDeleteChatMessage(id) {
  try {
    return await apiClient.delete(`/messages/${id}` );
  } catch (error) {
    console.error(error);
  }
}

export async function handleEditChatMessage(payload) {
  try {
    return await apiClient.put(`/messages/${payload.id}`, { content: payload.content } );
  } catch (error) {
    console.error(error);
  }
}

export async function handleDeleteOneTimeSeenPhoto(payload) {
  try {
    return await apiClient.delete(`/messages/${payload.message_id}/photos/${payload.photo_id}` );
  } catch (error) {
    console.error(error);
  }
}

